import { graphql } from 'gatsby';
import React from 'react';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import Ogp, { SiteProps } from '../components/Ogp';
import PostList, { PostListProps } from '../components/PostList';
import ArticleIcon from '../icons/Article/Article';

type Props = {
  data: {
    site: SiteProps;
    allMarkdownRemark: {
      posts: PostListProps;
    };
  };
};

const Index = (props: Props) => {
  const { data } = props;
  return (
    <Layout>
      <Ogp metadata={data.site} />
      <Heading icon={<ArticleIcon alt="最新記事" />}>最新記事</Heading>
      <PostList posts={data.allMarkdownRemark.posts} />
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery {
    site {
      ...OgpFragment
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      posts: edges {
        ...PostListFragment
      }
    }
  }
`;

export default Index;
